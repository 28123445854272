import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import Scene3 from "./scenes/Scene3";


import { useState } from "react";
import AuriniaNavbar from './Navbar';



export default function App() {
  const toggleMenu = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false); // State to control collapse



  return (
    <div className="App">
     <AuriniaNavbar />
      <Scene3 />
    </div>
  );
}