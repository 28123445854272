import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import arrowImage from "./img/right_arrow.png"
import Modal from 'react-bootstrap/Modal';

import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { put } from 'aws-amplify/api';
import "./thankyouModal.css";
import ReactGA from "react-ga4";

function SignUpForm() {

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState({ select1: "", select2: "" });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [isFirstNameInvalid, setFirstNameInvalid] = useState(false);
  const [isLastNameInvalid, setLastNameInvalid] = useState(false);
  const [isEmailInvalid, setisEmailInvalid] = useState(false);
  const [isZipInvalid, setisZipInvalid] = useState(false);
  

  const formbutton = document.getElementById('subbutton');
  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}]|[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
    return emailRegex.test(email);
  };

  const validateZip = (zipCode) => {
    const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipRegex.test(zipCode);
  };

  if ((firstName != "") && (lastName != "") && ( validateEmail(email)) && (validateZip(zipCode) )){
    formbutton.classList.remove('isDisabled');
  } else{
    if (formbutton){
      formbutton.classList.add('isDisabled');
    }
  }
    const areAllFieldsFilled = (firstName != "") && (lastName != "") && ( validateEmail(email))  && (validateZip(zipCode) );





  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue2, setSelectedValue2] = useState('');
  
  const handleSelectChange = (event) => {
    event.preventDefault();
   setSelectedValue(event.target.value);

   if (event.target.value != ""){
    console.log( event.target.classList);
    event.target.classList.remove('has-placeholder');
   }
  };
 // 
  const handleSelectChange2 = (event) => {
    event.preventDefault();
    setSelectedValue2(event.target.value);
    if (event.target.value != ""){
     console.log( event.target.classList);
     event.target.classList.remove('has-placeholder');
    }
   };
/* new form stuff */
   async function callAPIPutFunction() {
    try {
      const formData = { firstname: firstName, lastname: lastName, email:email, zip:zipCode, title:selectedValue, specialty:selectedValue2 };
      console.log(formData);
      const restOperation = put({
        apiName: 'apiphase1develop',
        path: '/api',
        options: {
          body: formData
        }
      });
      const response = await restOperation.response;
      console.log('PUT call succeeded: ', response);
      if(response.body){
      console.log("Body = ",response.body);
      
      } else {
        console.log("Can't find body on response")
      }
    } catch (e) {
      console.log("PUT call failed: ",e);
    }
  }


  console.log(isEmailInvalid);
  const handleSubmit = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if ((form.checkValidity()) ) {
    event.preventDefault();
    callAPIPutFunction();
      setValidated(false);
      setShow(true);
      setFirstName("");
      setLastName("");
      setEmail("");
      setZipCode("");
      setSelectedValue2('');
      setSelectedValue('');
      form.reset();
      event.target.reset();
      document.getElementById('subbutton').classList.add("isDisabled");
      ReactGA.event({
        category: "Click",
        action: "Opt-in",
        label: "Sign_Up_Complete",
      });
      firePixel();   
    }
  };

  function firePixel(){
    new Image().src ="https://beacon.deepintent.com/conversion?id=1770e098-aa22-400d-9eab-f00564e07cbc"
  }

  const handleCloseModal = () => setShow(false); // Function to close modal
  return (
    <div>
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="RegisterForm" id="form1">
    <Container fluid className="formContainer1">
   <Row className="mt-1 mt-xl-5 formcolmt justify-content-center">
       
            <Col lg={5}>
            <Form.Group className=" mb-4 mb-lg-5 formcolmb" controlId="validationCustom01">
          <Form.Control
            required
            type="text"
            placeholder="First name"
            value={firstName}
            isInvalid={isFirstNameInvalid}
            onChange={(e) => setFirstName(e.target.value)}

          />
        </Form.Group>
            </Col>
            <Col lg={5}>
            <Form.Group className=" mb-4 mb-lg-5 formcolmb" controlId="validationCustom02">
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            value={lastName}
            isInvalid={isLastNameInvalid}
            onChange={(e) => setLastName(e.target.value)}
           
          />
           </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={5}>
            <Form.Group className=" mb-4 mb-lg-5 formcolmb " controlId="formGroupEmail">
                <Form.Control
                required
                type="email" 
                placeholder="Email address"
                value={email}
                isInvalid={isEmailInvalid}
                onChange={(e) => setEmail(e.target.value)}
                
                />
            </Form.Group>
            </Col>
            <Col lg={5}>
            <Form.Group className=" mb-4 mb-lg-5 formcolmb" controlId="validationCustom01">
          <Form.Control
            required
            type="text"
            placeholder="Zip Code"
            pattern="\d{5}"
            value={zipCode}
            isInvalid={isZipInvalid}
            onChange={(e) => setZipCode(e.target.value)}
          
          />
           </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
          <Col lg={5}>
          <Form.Select aria-label="Select Title" name="select1" className="mb-4 mb-lg-5 has-placeholder" value={selectedValue} onChange={handleSelectChange}>
      <option value="" disabled selected >Title</option>
      <option value="DO">DO</option>
      <option value="MD">MD</option>
      <option value="NP">NP</option>
      <option value="PA">PA</option>
      <option value="RN">RN</option>
      <option value="Other">Other</option>
    </Form.Select>
          </Col>
          <Col lg={5}>
          <Form.Select aria-label="Select Specialty" name="select2" className="prevent-validation has-placeholder" value={selectedValue2} onChange={handleSelectChange2}>
      <option value="" disabled selected>My practice specialty</option>
      <option value="Primary care/internist">Primary care/internist</option>
      <option value="Rheumatologist">Rheumatologist</option>
      <option value="Nephrologist">Nephrologist</option>
      <option value="Other">Other</option>

    </Form.Select>
   
          </Col>
          <Row className="justify-content-center">
      <Col lg={10}><p className="mt-5 d-none d-xl-block marginoverride">
      By clicking Submit below, you agree that the information you voluntarily provide may be used by Aurinia Pharma U.S., Inc., its affiliates, or other companies acting on its behalf to provide you with educational materials and resources for lupus nephritis. Aurinia will handle your personal information in accordance with Aurinia's <a href="https://www.auriniapharma.com/privacy-statement" className="yellow-link" target="_blank">Privacy Statement</a>. For additional information, please read our Privacy Statement.
      </p>
      </Col>
    </Row>
          </Row>
       
      <p className="mt-2 d-xl-none">
      By clicking Submit below, you agree that the information you voluntarily provide may be used by Aurinia Pharma U.S., Inc., its affiliates, or other companies acting on its behalf to provide you with educational materials and resources for lupus nephritis. Aurinia will handle your personal information in accordance with Aurinia's <a href="https://www.auriniapharma.com/privacy-statement" className="yellow-link" target="_blank">Privacy Statement</a>. For additional information, please read our Privacy Statement.
      </p>
      <Row className="mt-5 d-flex justify-content-center pb-5 subbuttonoverride">
      <Button type="submit" className="btn btn-primary isDisabled" id="subbutton">Submit <img src={arrowImage} className="subimg"></img></Button>
      </Row>
  </Container>
  </Form>
  <Modal show={show} onHide={handleCloseModal} centered id="thankyoumodal"  data-bs-theme="dark"> 
  <Modal.Header closeButton>
    <Modal.Title>Thank you for registering</Modal.Title>
  </Modal.Header>
  <Modal.Body>
   <p><strong>Continue to explore insights on the warning signs of lupus nephritis to help manage your SLE patients</strong> </p>
  </Modal.Body>
  <Modal.Footer>
    <p className="modalfooter">SLE=systemic lupus erythematosus.</p>
  </Modal.Footer>
</Modal>
</div>
  );
}

export default SignUpForm;