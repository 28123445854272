/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createContactForm } from "../graphql/mutations";
const client = generateClient();
export default function ContactFormCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstName: "",
    email: "",
    lastName: "",
    npiNumber: "",
    phone: "",
    facilityName: "",
    zipcode: "",
    repContact: false,
    scienceLiason: false,
  };
  const [firstName, setFirstName] = React.useState(initialValues.firstName);
  const [email, setEmail] = React.useState(initialValues.email);
  const [lastName, setLastName] = React.useState(initialValues.lastName);
  const [npiNumber, setNpiNumber] = React.useState(initialValues.npiNumber);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [facilityName, setFacilityName] = React.useState(
    initialValues.facilityName
  );
  const [zipcode, setZipcode] = React.useState(initialValues.zipcode);
  const [repContact, setRepContact] = React.useState(initialValues.repContact);
  const [scienceLiason, setScienceLiason] = React.useState(
    initialValues.scienceLiason
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFirstName(initialValues.firstName);
    setEmail(initialValues.email);
    setLastName(initialValues.lastName);
    setNpiNumber(initialValues.npiNumber);
    setPhone(initialValues.phone);
    setFacilityName(initialValues.facilityName);
    setZipcode(initialValues.zipcode);
    setRepContact(initialValues.repContact);
    setScienceLiason(initialValues.scienceLiason);
    setErrors({});
  };
  const validations = {
    firstName: [],
    email: [{ type: "Email" }],
    lastName: [],
    npiNumber: [],
    phone: [{ type: "Phone" }],
    facilityName: [],
    zipcode: [],
    repContact: [],
    scienceLiason: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstName,
          email,
          lastName,
          npiNumber,
          phone,
          facilityName,
          zipcode,
          repContact,
          scienceLiason,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createContactForm.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ContactFormCreateForm")}
      {...rest}
    >
      <TextField
        label="First name"
        isRequired={false}
        isReadOnly={false}
        value={firstName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName: value,
              email,
              lastName,
              npiNumber,
              phone,
              facilityName,
              zipcode,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.firstName ?? value;
          }
          if (errors.firstName?.hasError) {
            runValidationTasks("firstName", value);
          }
          setFirstName(value);
        }}
        onBlur={() => runValidationTasks("firstName", firstName)}
        errorMessage={errors.firstName?.errorMessage}
        hasError={errors.firstName?.hasError}
        {...getOverrideProps(overrides, "firstName")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              email: value,
              lastName,
              npiNumber,
              phone,
              facilityName,
              zipcode,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Last name"
        isRequired={false}
        isReadOnly={false}
        value={lastName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName: value,
              npiNumber,
              phone,
              facilityName,
              zipcode,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.lastName ?? value;
          }
          if (errors.lastName?.hasError) {
            runValidationTasks("lastName", value);
          }
          setLastName(value);
        }}
        onBlur={() => runValidationTasks("lastName", lastName)}
        errorMessage={errors.lastName?.errorMessage}
        hasError={errors.lastName?.hasError}
        {...getOverrideProps(overrides, "lastName")}
      ></TextField>
      <TextField
        label="Npi number"
        isRequired={false}
        isReadOnly={false}
        value={npiNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName,
              npiNumber: value,
              phone,
              facilityName,
              zipcode,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.npiNumber ?? value;
          }
          if (errors.npiNumber?.hasError) {
            runValidationTasks("npiNumber", value);
          }
          setNpiNumber(value);
        }}
        onBlur={() => runValidationTasks("npiNumber", npiNumber)}
        errorMessage={errors.npiNumber?.errorMessage}
        hasError={errors.npiNumber?.hasError}
        {...getOverrideProps(overrides, "npiNumber")}
      ></TextField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        type="tel"
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName,
              npiNumber,
              phone: value,
              facilityName,
              zipcode,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="Facility name"
        isRequired={false}
        isReadOnly={false}
        value={facilityName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName,
              npiNumber,
              phone,
              facilityName: value,
              zipcode,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.facilityName ?? value;
          }
          if (errors.facilityName?.hasError) {
            runValidationTasks("facilityName", value);
          }
          setFacilityName(value);
        }}
        onBlur={() => runValidationTasks("facilityName", facilityName)}
        errorMessage={errors.facilityName?.errorMessage}
        hasError={errors.facilityName?.hasError}
        {...getOverrideProps(overrides, "facilityName")}
      ></TextField>
      <TextField
        label="Zipcode"
        isRequired={false}
        isReadOnly={false}
        value={zipcode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName,
              npiNumber,
              phone,
              facilityName,
              zipcode: value,
              repContact,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.zipcode ?? value;
          }
          if (errors.zipcode?.hasError) {
            runValidationTasks("zipcode", value);
          }
          setZipcode(value);
        }}
        onBlur={() => runValidationTasks("zipcode", zipcode)}
        errorMessage={errors.zipcode?.errorMessage}
        hasError={errors.zipcode?.hasError}
        {...getOverrideProps(overrides, "zipcode")}
      ></TextField>
      <SwitchField
        label="Rep contact"
        defaultChecked={false}
        isDisabled={false}
        isChecked={repContact}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName,
              npiNumber,
              phone,
              facilityName,
              zipcode,
              repContact: value,
              scienceLiason,
            };
            const result = onChange(modelFields);
            value = result?.repContact ?? value;
          }
          if (errors.repContact?.hasError) {
            runValidationTasks("repContact", value);
          }
          setRepContact(value);
        }}
        onBlur={() => runValidationTasks("repContact", repContact)}
        errorMessage={errors.repContact?.errorMessage}
        hasError={errors.repContact?.hasError}
        {...getOverrideProps(overrides, "repContact")}
      ></SwitchField>
      <SwitchField
        label="Science liason"
        defaultChecked={false}
        isDisabled={false}
        isChecked={scienceLiason}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              email,
              lastName,
              npiNumber,
              phone,
              facilityName,
              zipcode,
              repContact,
              scienceLiason: value,
            };
            const result = onChange(modelFields);
            value = result?.scienceLiason ?? value;
          }
          if (errors.scienceLiason?.hasError) {
            runValidationTasks("scienceLiason", value);
          }
          setScienceLiason(value);
        }}
        onBlur={() => runValidationTasks("scienceLiason", scienceLiason)}
        errorMessage={errors.scienceLiason?.errorMessage}
        hasError={errors.scienceLiason?.hasError}
        {...getOverrideProps(overrides, "scienceLiason")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
