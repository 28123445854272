import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { studioTheme } from './ui-components';
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import amplifyconfig from './amplifyconfiguration.json';
import App from './App';




Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <React.StrictMode>
    <App />
  </React.StrictMode>

);

