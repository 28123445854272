import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Collapse } from 'react-bootstrap';
import './Navbar.css'; // Import your custom CSS file
import navbarBrandimg from '../src/img/mobile-know-logo.webp';
import ReactGA from "react-ga4";
function AuriniaNavbar() {
  const mobileGAEvents = (e) => {
    var getGaName = e.currentTarget.getAttribute("data-name");
   //console.log("GA EVENT MOBILE = ", getGaName);
   ReactGA.event({
    category: "Click",
    action: "Interactive",
    label: getGaName,
  });
  }

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    return (
        <Navbar expand="xl" id="navbar"
        fixed="top" collapseOnSelect>
          <Navbar.Brand href="#morbidity-and-mortality"><img src={navbarBrandimg} alt="Lupus Nephritus Know the Signs" className='navbar-brand-image mx-3 pt-1 pb-2'></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mobile-nav me-auto">
              <Nav.Link className="navlinks" href="#morbidity-and-mortality" onClick={mobileGAEvents} data-name="Morbidity_menu_button">Morbidity and Mortality</Nav.Link>
              <Nav.Link className="navlinks" href="#proteinuria-limits" onClick={mobileGAEvents} data-name="Protenuria_menu_button">Proteinuria Limits</Nav.Link>
              <Nav.Link className="mobile-menu-cta" href="#sign-up" onClick={mobileGAEvents} data-name="Sign_up_menu_button">SIGN UP FOR UPDATES</Nav.Link>
            </Nav>
          </Navbar.Collapse>
       
      </Navbar>
    );
}

export default AuriniaNavbar;