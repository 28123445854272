/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createSignUpForm } from "../graphql/mutations";
const client = generateClient();
export default function SignUpFormCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    specialty: "",
    zipcode: "",
    interestOption1: false,
    interestOption2: false,
    interestOption3: false,
    interestOption4: false,
    interestOption5: false,
    interestOption6: false,
  };
  const [firstName, setFirstName] = React.useState(initialValues.firstName);
  const [lastName, setLastName] = React.useState(initialValues.lastName);
  const [email, setEmail] = React.useState(initialValues.email);
  const [title, setTitle] = React.useState(initialValues.title);
  const [specialty, setSpecialty] = React.useState(initialValues.specialty);
  const [zipcode, setZipcode] = React.useState(initialValues.zipcode);
  const [interestOption1, setInterestOption1] = React.useState(
    initialValues.interestOption1
  );
  const [interestOption2, setInterestOption2] = React.useState(
    initialValues.interestOption2
  );
  const [interestOption3, setInterestOption3] = React.useState(
    initialValues.interestOption3
  );
  const [interestOption4, setInterestOption4] = React.useState(
    initialValues.interestOption4
  );
  const [interestOption5, setInterestOption5] = React.useState(
    initialValues.interestOption5
  );
  const [interestOption6, setInterestOption6] = React.useState(
    initialValues.interestOption6
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFirstName(initialValues.firstName);
    setLastName(initialValues.lastName);
    setEmail(initialValues.email);
    setTitle(initialValues.title);
    setSpecialty(initialValues.specialty);
    setZipcode(initialValues.zipcode);
    setInterestOption1(initialValues.interestOption1);
    setInterestOption2(initialValues.interestOption2);
    setInterestOption3(initialValues.interestOption3);
    setInterestOption4(initialValues.interestOption4);
    setInterestOption5(initialValues.interestOption5);
    setInterestOption6(initialValues.interestOption6);
    setErrors({});
  };
  const validations = {
    firstName: [],
    lastName: [],
    email: [{ type: "Email" }],
    title: [],
    specialty: [],
    zipcode: [],
    interestOption1: [],
    interestOption2: [],
    interestOption3: [],
    interestOption4: [],
    interestOption5: [],
    interestOption6: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstName,
          lastName,
          email,
          title,
          specialty,
          zipcode,
          interestOption1,
          interestOption2,
          interestOption3,
          interestOption4,
          interestOption5,
          interestOption6,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createSignUpForm.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "SignUpFormCreateForm")}
      {...rest}
    >
      <TextField
        label="First name"
        isRequired={false}
        isReadOnly={false}
        value={firstName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName: value,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.firstName ?? value;
          }
          if (errors.firstName?.hasError) {
            runValidationTasks("firstName", value);
          }
          setFirstName(value);
        }}
        onBlur={() => runValidationTasks("firstName", firstName)}
        errorMessage={errors.firstName?.errorMessage}
        hasError={errors.firstName?.hasError}
        {...getOverrideProps(overrides, "firstName")}
      ></TextField>
      <TextField
        label="Last name"
        isRequired={false}
        isReadOnly={false}
        value={lastName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName: value,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.lastName ?? value;
          }
          if (errors.lastName?.hasError) {
            runValidationTasks("lastName", value);
          }
          setLastName(value);
        }}
        onBlur={() => runValidationTasks("lastName", lastName)}
        errorMessage={errors.lastName?.errorMessage}
        hasError={errors.lastName?.hasError}
        {...getOverrideProps(overrides, "lastName")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email: value,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Title"
        isRequired={false}
        isReadOnly={false}
        value={title}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title: value,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.title ?? value;
          }
          if (errors.title?.hasError) {
            runValidationTasks("title", value);
          }
          setTitle(value);
        }}
        onBlur={() => runValidationTasks("title", title)}
        errorMessage={errors.title?.errorMessage}
        hasError={errors.title?.hasError}
        {...getOverrideProps(overrides, "title")}
      ></TextField>
      <TextField
        label="Specialty"
        isRequired={false}
        isReadOnly={false}
        value={specialty}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty: value,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.specialty ?? value;
          }
          if (errors.specialty?.hasError) {
            runValidationTasks("specialty", value);
          }
          setSpecialty(value);
        }}
        onBlur={() => runValidationTasks("specialty", specialty)}
        errorMessage={errors.specialty?.errorMessage}
        hasError={errors.specialty?.hasError}
        {...getOverrideProps(overrides, "specialty")}
      ></TextField>
      <TextField
        label="Zipcode"
        isRequired={false}
        isReadOnly={false}
        value={zipcode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode: value,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.zipcode ?? value;
          }
          if (errors.zipcode?.hasError) {
            runValidationTasks("zipcode", value);
          }
          setZipcode(value);
        }}
        onBlur={() => runValidationTasks("zipcode", zipcode)}
        errorMessage={errors.zipcode?.errorMessage}
        hasError={errors.zipcode?.hasError}
        {...getOverrideProps(overrides, "zipcode")}
      ></TextField>
      <SwitchField
        label="Interest option1"
        defaultChecked={false}
        isDisabled={false}
        isChecked={interestOption1}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1: value,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.interestOption1 ?? value;
          }
          if (errors.interestOption1?.hasError) {
            runValidationTasks("interestOption1", value);
          }
          setInterestOption1(value);
        }}
        onBlur={() => runValidationTasks("interestOption1", interestOption1)}
        errorMessage={errors.interestOption1?.errorMessage}
        hasError={errors.interestOption1?.hasError}
        {...getOverrideProps(overrides, "interestOption1")}
      ></SwitchField>
      <SwitchField
        label="Interest option2"
        defaultChecked={false}
        isDisabled={false}
        isChecked={interestOption2}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2: value,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.interestOption2 ?? value;
          }
          if (errors.interestOption2?.hasError) {
            runValidationTasks("interestOption2", value);
          }
          setInterestOption2(value);
        }}
        onBlur={() => runValidationTasks("interestOption2", interestOption2)}
        errorMessage={errors.interestOption2?.errorMessage}
        hasError={errors.interestOption2?.hasError}
        {...getOverrideProps(overrides, "interestOption2")}
      ></SwitchField>
      <SwitchField
        label="Interest option3"
        defaultChecked={false}
        isDisabled={false}
        isChecked={interestOption3}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3: value,
              interestOption4,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.interestOption3 ?? value;
          }
          if (errors.interestOption3?.hasError) {
            runValidationTasks("interestOption3", value);
          }
          setInterestOption3(value);
        }}
        onBlur={() => runValidationTasks("interestOption3", interestOption3)}
        errorMessage={errors.interestOption3?.errorMessage}
        hasError={errors.interestOption3?.hasError}
        {...getOverrideProps(overrides, "interestOption3")}
      ></SwitchField>
      <SwitchField
        label="Interest option4"
        defaultChecked={false}
        isDisabled={false}
        isChecked={interestOption4}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4: value,
              interestOption5,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.interestOption4 ?? value;
          }
          if (errors.interestOption4?.hasError) {
            runValidationTasks("interestOption4", value);
          }
          setInterestOption4(value);
        }}
        onBlur={() => runValidationTasks("interestOption4", interestOption4)}
        errorMessage={errors.interestOption4?.errorMessage}
        hasError={errors.interestOption4?.hasError}
        {...getOverrideProps(overrides, "interestOption4")}
      ></SwitchField>
      <SwitchField
        label="Interest option5"
        defaultChecked={false}
        isDisabled={false}
        isChecked={interestOption5}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5: value,
              interestOption6,
            };
            const result = onChange(modelFields);
            value = result?.interestOption5 ?? value;
          }
          if (errors.interestOption5?.hasError) {
            runValidationTasks("interestOption5", value);
          }
          setInterestOption5(value);
        }}
        onBlur={() => runValidationTasks("interestOption5", interestOption5)}
        errorMessage={errors.interestOption5?.errorMessage}
        hasError={errors.interestOption5?.hasError}
        {...getOverrideProps(overrides, "interestOption5")}
      ></SwitchField>
      <SwitchField
        label="Interest option6"
        defaultChecked={false}
        isDisabled={false}
        isChecked={interestOption6}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstName,
              lastName,
              email,
              title,
              specialty,
              zipcode,
              interestOption1,
              interestOption2,
              interestOption3,
              interestOption4,
              interestOption5,
              interestOption6: value,
            };
            const result = onChange(modelFields);
            value = result?.interestOption6 ?? value;
          }
          if (errors.interestOption6?.hasError) {
            runValidationTasks("interestOption6", value);
          }
          setInterestOption6(value);
        }}
        onBlur={() => runValidationTasks("interestOption6", interestOption6)}
        errorMessage={errors.interestOption6?.errorMessage}
        hasError={errors.interestOption6?.hasError}
        {...getOverrideProps(overrides, "interestOption6")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
