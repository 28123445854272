import gsap from "gsap";
import React from 'react';
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Observer } from "gsap/Observer";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { CustomEase } from "gsap/CustomEase";

import { useLayoutEffect, useRef } from "react";
import { useState } from "react";

import "../styles3.css";
import "../styles4.css";
import roughRoadSign from '../img/0.0_rough_road_sign.webp';
import logo_mark from '../img/logo_mark.svg';
import aurinia_logo from '../img/Aurinia-Logo-Color-White-051920-v01.svg';
import alert_icon from '../img/question-alert-icon.webp';
import lnws_logo from '../img/Logo.svg';
import scrollimage from '../img/scroll-arrow.png';
import proteinuriaRoadSign from '../img/2.0_proteinuria_limit_sign.webp';
import bgOverLay from "../img/bg-overlay1.webp"
import rightarrowred from "../img/right-arrow-red.png"
import threepointfour from "../img/3.4x.webp"
import nineximage from "../img/9x.webp"
import signupdivider from "../img/sign-up-divider.png"
import proteinuriadivider from "../img/proteinuria-limits-divider.png"
import morbidityMortalityDivider from "../img/morbidity-and-mortality-divider.png"

import SignUpForm from '../SignUpForm'
import SignUpFormMobile from '../SignUpFormMobile'
//import ShowCookieComponent from '../ShowCookieComponent'


/* mobile */
import scrollimagemobile from '../img/scroll-arrow-mobile.png';
import threepointfourmobile from "../img/3.4-mobile.webp"
import nineximagemobile from "../img/9x-mobile.webp"
import AuriniaLogomobile from '../img/Aurinia-Logo-Color-White-051920-v01.svg'
import ReactGA from "react-ga4";
ReactGA.initialize("G-M53ZFBLXP8");
console.clear();



export default function App() {
    const mobileGAEvents = (e) => {
        var getGaName = e.currentTarget.getAttribute("data-name");
       //console.log("GA EVENT MOBILE = ", getGaName);
       ReactGA.event({
        category: "Click",
        action: "Interactive",
        label: getGaName,
      });
      }
      
    /* clear scroll trigger memory and reset timelines.*/
    ScrollTrigger.clearScrollMemory();
    window.history.scrollRestoration = "manual";


    const [show, setShow] = useState(false);


    const [showMobileModals, setShowModals] = useState([false, false]);

    const component = useRef();
    let smoother;

    (function () {
        var s = document.createElement('script');
        s.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js';
        s.setAttribute('data-tenant-uuid', '33fca90b-fa59-42cb-b2d8-aa9dcdabd81e');
        s.setAttribute('data-domain-uuid', 'ac034cc8-3717-4c4c-86a7-3375699c64f2');
        s.setAttribute('data-backend-url', 'https://app.securiti.ai');
        s.setAttribute('data-securiti-staging-mode', 'true');
        s.setAttribute('data-skip-css', 'false');
        s.defer = true;
        var parent_node = document.head || document.body;
        parent_node.appendChild(s);
    })()

    // check if visible mobile//
    function onVisible(element, callback) {
        new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if(entry.intersectionRatio >  0.10 ) {
              callback(element);
              observer.disconnect();
            }
          });
        }).observe(element);
        if(!callback) return new Promise(r => callback=r);
      }


      useLayoutEffect(() => {
        // Code to execute when DOM is loaded
        onVisible(document.querySelector("#mobile1"), () =>  
            ReactGA.event({
            category: "Scroll",
            action: "Navigation",
            label: "Morbidity_page1",
          })
        );
        onVisible(document.querySelector("#mobile2"), () =>  
            ReactGA.event({
            category: "Scroll",
            action: "Navigation",
            label: "Morbidity_page2",
          })
        );
        onVisible(document.querySelector("#mobile3"), () =>  
            ReactGA.event({
            category: "Scroll",
            action: "Navigation",
            label: "Protenuria_page1",
          })
        );
        onVisible(document.querySelector("#mobile4"), () => 
            ReactGA.event({
                category: "Scroll",
                action: "Navigation",
                label: "Protenuria_page2",
              })
        );
        onVisible(document.querySelector("#mobile6"), () => ReactGA.event({
            category: "Scroll",
            action: "Navigation",
            label: "Sign_up_page",
          })
        );
      }, []);

      
      
    
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CustomEase, ScrollToPlugin);

        let ctx = gsap.context(() => {
            
            /*smoother = ScrollSmoother.create({
              markers: false,
              smooth: 3, // how long (in seconds) it takes to "catch up" to the native scroll position
              effects: false, // looks for data-speed and data-lag attributes on elements
              smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
              speed:0.8,
            });*/

            //Reference to the container
            let container = document.querySelector(".content-container")

            //Array of .panel objects
            let sections = gsap.utils.toArray(".panel");

            /************************ TWEEN WITH STOPS (Slows down on each Section) *****************************/

            //Define stops.
            let stops = []
            sections.forEach((section, index) => {
                if (section.dataset.pin) {
                    stops.push(index);
                }
            })

            //Create a new timeline
            let tl = gsap.timeline({
                defaults: {
                    ease: "none",
                },
                scrollTrigger: {
                    trigger: container,
                    scrub: 3,
                    pin: true,
                    invalidateOnRefresh: true,
                    end: `+=${container.offsetWidth * 1.5}` //This is *2 to affect how far the user has to scroll. Consider container.offsetWidth 1.0 and multiply from there.
                }
            })

            //Create Start Label
            tl.addLabel("panel0");
            console.log("LABEL: panel0")

            //Iterate through each stop to add in proper animations to the timeline. Use Animation chaining to manage frames to slow down at.
            stops.forEach((stop, index) => {
                const pinnedSection = gsap.utils.selector(sections[stop]);

                tl.to(sections, {
                    xPercent: -(100 * stop),
                    duration: stop
                })
                tl.to(pinnedSection(".sub-animation"), {
                    x: -100,
                    ease: CustomEase.create("custom", "M0,0 C0,0 0.202,0.453 0.496,0.496 0.797,0.539 1,1 1,1 "),
                    duration: 1
                })
                tl.addLabel("panel" + stop)
                console.log("LABEL: panel" + stop)

            })

            //Add a label to cap the previous label's animation.
            tl.addLabel("preend");

            tl.to(sections, {
                xPercent: -(100 * (sections.length - 1)),
                duration: sections.length - 2
            })

            //Add a label to be able to scroll to the end.
            tl.addLabel("panel4");
            console.log("LABEL: panel4")

            /************************ MANAGING ANCHORS (Click to Scroll) *****************************/
            let anchors = gsap.utils.toArray(".anchor");
            anchors.forEach((a) => {

                //Add click listener to navigate to labels.
                a.addEventListener("click", function (e) {
                    e.preventDefault();
                    var href = e.target.getAttribute("href")
                    var getGaName = e.target.getAttribute("data-name")
                    console.log("data-name = ", getGaName)
                    console.log("HREF = ", href);
                    var offset = 0;

                    ReactGA.event({
                        category: "Click",
                        action: "Interactive",
                        label: getGaName,
                      });


                    if (href == "panel4") {
                        //offset = container.offsetWidth
                    }
                    if (tl.scrollTrigger) {
                        console.log("tl.scrolltrigger = ", tl.scrollTrigger.labelToScroll(href));
                        gsap.to(window, {
                            scrollTo: tl.scrollTrigger.labelToScroll(href) + offset
                        })
                    }
                })
            })

            let excludes = gsap.utils.toArray(".excludeme");
 
            excludes.forEach((exclude) => {
                exclude.addEventListener("click",function(e){
                    e.preventDefault();
                })
                
            })


            /************************ MANAGING LINKS (Desktop Nav Highlights) *****************************/
            let links = gsap.utils.toArray(".link");

            links.forEach((link, index) => {
                let associatedPanel = document.querySelector(link.dataset.link)

                if (index == 0) {
                    ScrollTrigger.create({
                        markers: false,
                        trigger: associatedPanel,
                        containerAnimation: tl,
                        start: "center 80%",
                        end: "right 79%",
                        onEnter: () => {
                            setActive(link)
                        },
                        onEnterBack: () => {
                            setActive(link)
                        }
                    })
                } else {
                    ScrollTrigger.create({
                        trigger: associatedPanel,
                        containerAnimation: tl,
                        start: "top 80%",
                        end: "left 79%",
                        onEnter: () => {
                            console.log("ON onEnter")
                            setActive(link)
                        }
                    })
 
                    ScrollTrigger.create({
                        trigger: associatedPanel,
                        containerAnimation: tl,
                        start: "bottom 100%",
                        end: "bottom 101%",
                        onEnterBack: () => {
                            console.log("ON onEnterBACK")
                            setActive(link)
                        }
                    })
                }
            })

            function setActive(anchor) {
                anchors.forEach(el => el.classList.remove("active"));
                anchor.classList.add("active");
            }




            //************************ ANIMATIONS - ON START *****************************/
            gsap.set(".slide-1-bg-img", { scale: 1.3, x: 100 });
            gsap.set(".slide-5-bg-img", { opacity:0, scale: 1.3, x: -100 });
            gsap.set(".bg-img-overlay-5-ani-1",{opacity: 1})
            gsap.set(".bg-img-overlay-1", { opacity: 0 });
            gsap.set(".roadsign1container", { opacity: 0, scale: .7, y: 159, x: 700 });
            //gsap.set(".desktopMenu", { opacity: 0 });
            gsap.set(".fn1", { opacity: 0 });
            gsap.set(".fn2", { opacity: 0 });
            gsap.set(".fn3", { opacity: 0 });
            gsap.set(".fn4", { opacity: 0 });


            //Open Animation
            gsap.set(".openSlideAnimation", { opacity: 0 });
            const openAnimation = gsap.timeline({
                paused: true,
                defaults: {}
            }).to(".openSlideAnimation", { opacity: 1, duration: 1.5, ease: "power1.out" }, "+=1");
            openAnimation.play();

            //Slide 1 Animation
            gsap.set(".slide1animation", { opacity: 0 });
            const slideOneAnimation = gsap.timeline({
                paused: true,
                defaults: {}
            }).to(".slide1animation", {
                opacity: 1, duration: 1.5,
            }, "+=2")
                .to(".fn1", { opacity: 1, duration: 1, ease: "power1.out" });

            slideOneAnimation.play();

            //BG One Animation.


            const bgOneAnimation = gsap.timeline({
                paused: true,
                defaults: {}
            }).to(".slide-1-bg-img", { x: -140, duration: 1.0, ease: "sine.inOut" }, "1")

            bgOneAnimation.play();






            //************************ ANIMATIONS - SCROLL TRIGGERS *****************************/


            //From Slide 1 to Slide 2 - Fades the initial background by animating an overlay.
            let backgroundOverlay1FadeOn = gsap.to(".bg-img-overlay-1", {
                opacity: 1,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: "left 100%",
                    end: "left 60%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })

            //From Slide 1 to Slide 2 - Fades the initial background by animating an overlay.
            let backgroundOneAnimateOff = gsap.to(".slide-1-bg-img", {
                x:-220,
                ease: "power1.out",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: "left 130%",
                    end: "left 80%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })


            //From Slide 2 Into SLide 3, Animate the slide-2-bg-img on
            gsap.set(".slide-2-bg-img", { opacity: 0, scale: 1.3, x: 100 });
            let bgTwoFadeOn = gsap.to(".slide-2-bg-img", {
                opacity: 1.0,
                x: -100,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: "left 130%",
                    end: "left 80%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })


            //From Slide 3 into slide 4, fade the bg-image-overlay-2
            gsap.set(".bg-img-overlay-2-ani-1", { opacity: 0 });
            let bgTwoOverlay1FadeOn = gsap.to(".bg-img-overlay-2-ani-1", {
                opacity: 1.0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide4",
                    containerAnimation: tl,
                    start: "left 130%",
                    end: "left 50%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play",
                    onLeave:()=> {gsap.set(".slide-5-bg-img",{opacity:1})},
                    onEnterBack:() => {gsap.set(".slide-5-bg-img",{opacity:0})}
                }
            })


            //From Slide 4 into Slide 5, fade teh bg-img-overlay-2 off
            let bgTwoOverlay1Fadeff = gsap.to(".bg-img-overlay-2-ani-2", {
                opacity: 0.0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: "left 130%",
                    end: "left 50%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play",
                },

            })


            let bgThreeFadeOn = gsap.to(".slide-5-bg-img",{
                x:-200,
                ease: "sine.inOut",
                scrollTrigger:{
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: "left 130%",
                    end: "left 30%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })

            let bgThreeAni1FadeOff = gsap.to(".bg-img-overlay-5-ani-1",{
                opacity:0,
                ease: "sine.inOut",
                scrollTrigger:{
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: "left 130%",
                    end: "left 50%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })





            //RoadSignOne


            const roadSignOne = gsap.timeline({
                paused: true,
                defaults: {}
            })
                .to(".roadsign1container", { opacity: 1, scale: 1.3, y: 100, x: -120, duration: 1.0, ease: "sine.inOut" }, "+=1", 2)

            roadSignOne.play();

            //Animating Road Sign 1 off.
            //Using two different gsap.to moves since roadsign 1 moves and fades at different speeds.
            let roadSignSlide1MoveOff = gsap.to(".roadsign1", {
                x: -100,
                y: -20,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: "left 30%",
                    end: "left 0%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })

            let roadSignSlide1FadeOff2 = gsap.to(".roadsign1", {
                opacity: 0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: "left 30%",
                    end: "left 15%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                },
                onEnter: () => { }
            })




            //RoadSign Two
            gsap.set(".roadsign2container", { opacity: 0, scale: 1, y: 159, x: 200 });//gsap.set(".roadsign2container", { opacity: 0, scale: 1, y: 159, x: 200 });
            let roadSignTwoOn = gsap.to(".roadsign2container", {
                opacity: 1,
                scale: 1.1,
                y: 100,
                x: -80,
                ease: "power1.out",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: "left 110%",
                    end: "left 50%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })

            let roadSignTwoMoveOff = gsap.to(".roadsign2subcontainer", {
                x: -270,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: "left 100%",
                    end: "left 30%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })

            let roadSignTwoFadeOff = gsap.to(".roadsign2subcontainer", {
                opacity: 0.0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: "left 100%",
                    end: "left 40%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })


            //roadSignTwoAnimateOff.to(".roadsign2", { x: -240, duration: .4, opacity: 0 }, "+=1.3")
            const roadSignTwoAnimateOff = gsap.timeline({
                paused: true,
                defaults: {},
                scrollTrigger: {
                    markers: false,
                    trigger: ".slide4",
                    containerAnimation: tl,
                    start: "left 100%",
                    scrub: 1,
                    ease: "none",
                    toggleActions: "play pause pause play"
                }
            })






            //Reposition elements for slide text animations.
            gsap.set(".s2a", { x: 100 });
            gsap.set(".s2b", { x: 200 });
            gsap.set(".s2c", { x: 300 });

            gsap.set(".s3a", { x: 100 });
            gsap.set(".s3b", { x: 200 });
            gsap.set(".s3c", { x: 300 });

            gsap.set(".s4a", { x: 100 });
            gsap.set(".s4b", { x: 200 });
            gsap.set(".s4c", { x: 300 });

            gsap.set(".s5a", { x: 200 });
            gsap.set(".s5b", { x: 300 });

            //Slide 2 Text Animations

            let slideTextAnimationStart = "left 100%";
            let slideTextAnimationEnd = "left 50%";

            gsap.to(".s2a", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s2a"
                }
            });
            //s2b
            gsap.to(".s2b", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s2b"
                }
            });

            //s2c
            gsap.to(".s2c", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s2c"
                }
            });



            //Slide 3 Animatinos
            //s3a
            gsap.to(".s3a", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "2"
                }
            });
            //s3b
            gsap.to(".s3b", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "3"
                }
            });

            //s3c
            gsap.to(".s3c", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "4"
                }
            });




            //Slide 4 Text Animations
            //s4a
            gsap.to(".s4a", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide4",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s4a"
                }
            });
            //s4b
            gsap.to(".s4b", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide4",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s4b"
                }
            });
            //s4c
            gsap.to(".s4c", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide4",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s4c"
                }
            });




            //SLide 5 text animations
            gsap.to(".s5a", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s5a"
                }
            });
            //s4b
            gsap.to(".s5b", {
                x: 0, duration: 3,
                ease: "none",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: slideTextAnimationStart,
                    end: slideTextAnimationEnd,
                    scrub: 1,
                    ease: "none",
                    id: "s5b"
                }
            });






            //Footnote - Slide 1 to Slide 2
            let footnote1off = gsap.to(".fn1-subcontainer", {
                opacity: 0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: "left 80%",
                    end: "left 75%",
                    scrub: 1,
                    ease: "none",

                }
            })

            let footnote2on = gsap.to(".fn2", {
                opacity: 1,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide2",
                    containerAnimation: tl,
                    start: "left 75%",
                    end: "left 70%",
                    scrub: 1,
                    ease: "none",

                }
            })



            //Footnote - Slide 2 to Slide 3
            let footnote2off = gsap.to(".fn2-subcontainer", {
                opacity: 0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: "left 80%",
                    end: "left 75%",
                    scrub: 1,
                    ease: "none",

                }
            })

            let footnote3on = gsap.to(".fn3", {
                opacity: 1,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide3",
                    containerAnimation: tl,
                    start: "left 75%",
                    end: "left 70%",
                    scrub: 1,
                    ease: "none",

                }
            })




            //Footnote - Slide 3 to Slide 4
            let footnote3off = gsap.to(".fn3-subcontainer", {
                opacity: 0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide4",
                    containerAnimation: tl,
                    start: "left 80%",
                    end: "left 75%",
                    scrub: 1,
                    ease: "none",

                }
            })

            let footnote4on = gsap.to(".fn4", {
                opacity: 1,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide4",
                    containerAnimation: tl,
                    start: "left 75%",
                    end: "left 70%",
                    scrub: 1,
                    ease: "none",

                }
            })



            //Footnote - Slide 4 to Slide 5
            let footnote4off = gsap.to(".fn4-subcontainer", {
                opacity: 0,
                ease: "sine.inOut",
                scrollTrigger: {
                    markers: false,
                    trigger: "#slide5",
                    containerAnimation: tl,
                    start: "left 80%",
                    end: "left 75%",
                    scrub: 1,
                    ease: "none",

                }
            })

            //************************ GA-4 Slide Triggers *****************************/
        ScrollTrigger.create({
            trigger: "#slide1",
            containerAnimation: tl,
            start: "top 80%",
            end: "left 79%",
            onEnter: () => {
                ReactGA.event({
                    category: "Navigation",
                    action: "Scroll",
                    label: "Morbidity page 1",
                  });
            }
        })

        ScrollTrigger.create({
            trigger: "#slide2",
            containerAnimation: tl,
            start: "top 80%",
            end: "left 79%",
            onEnter: () => {
                ReactGA.event({
                    category: "Navigation",
                    action: "Scroll",
                    label: "Morbidity page 2",
                  });
            }
        })

        ScrollTrigger.create({
            trigger: "#slide3",
            containerAnimation: tl,
            start: "top 80%",
            end: "left 79%",
            onEnter: () => {
                ReactGA.event({
                    category: "Navigation",
                    action: "Scroll",
                    label: "Protenuria_page1",
                  });
            }
        })

        ScrollTrigger.create({
            trigger: "#slide4",
            containerAnimation: tl,
            start: "top 80%",
            end: "left 79%",
            onEnter: () => {
                ReactGA.event({
                    category: "Navigation",
                    action: "Scroll",
                    label: "Protenuria_page2",
                  });
            }
        })

        ScrollTrigger.create({
            trigger: "#slide5",
            containerAnimation: tl,
            start: "top 80%",
            end: "left 79%",
            onEnter: () => {
                ReactGA.event({
                    category: "Navigation",
                    action: "Scroll",
                    label: "Sign_up_page",
                  });
            }
        })
                

        }, component);
       
        return () => ctx.revert();
        
    });
    return (
        
        <main>
            <div>
                <div ref={component} className="desktopcomponent">

                    <div className="navigation-container">
                        <div className="lnwarningsignslogoContainer slide1animation">
                            <div className="lnwarningsignslogo">
                                <img src={lnws_logo} alt="Lgo" className='lnws-logo anchor' data-name="Home_page_button" href="panel0"></img>
                            </div>
                        </div>
                        <div className="greenNavbar openSlideAnimation" >
                            <img src={logo_mark} alt="Logo" className='logo_mark anchor' data-name="Home_page_button" href="panel0"></img>
                            <div className="menu">
                                <div className="menu_item">
                                    <a data-section="slide1" href="panel0" data-link="#slide1" data-name="Morbidity_menu_button" className="anchor link">Morbidity and Mortality</a>
                                </div>
                                <div className="menu_item">
                                    <a data-section="slide3" href="panel2" data-link="#slide3" data-name="Protenuria_menu_button" className="anchor link">Proteinuria Limits</a>
                                </div>
                                <div className="menu_item">
                                    <a data-section="slide5" href="panel4" data-link="#slide5" data-name="Sign_up_menu_button" className="anchor link">Sign up</a>
                                </div>
                            </div>
                        </div>
                        <div className="sign-off openSlideAnimation">
                            <div className="sign-off-info">
                                <p>The information on this website is provided by Aurinia for educational <br />purposes only and is intended for US health care professionals only. <br />It should not be used for diagnosis or treating a health problem or disease.</p></div>
                            <div className="sign-off-logo-area">
                                <a href="https://www.auriniapharma.com/" target="_blank"> <img src={aurinia_logo} alt="Aurinia Logo" className='aurinia-logo openSlideAnimation'></img></a>
                                <p>Trademarks and logos are the property of Aurinia Pharmaceuticals Inc.<br />
                                    ©2024 Aurinia Pharma U.S., Inc. All rights reserved. (09/24) US-NA-2400204</p></div>
                        </div>
                        <a href="panel4" className="signupbutton openSlideAnimation anchor" data-name="Sign_up_button">Sign up for updates</a>
                    </div>


                    <div className="scrollContainer" id="">
                        <div id="smooth-wrapper">
                            <div id="smooth-content">
                                <div className="content-container">
                                    <div className="slide-bg-container slide-1-bg-img">
                                        <img src={bgOverLay} alt="bg-image-overlay" className='bg-img-overlay-1'></img>
                                    </div>

                                    
                                    
                                    <div className="slide-bg-container slide-2-bg-img">
                                        <div className="bg-img-overlay-2-ani-1">
                                            <img src={bgOverLay} alt="bg-image-overlay" className='bg-img-overlay-2-ani-2'></img>
                                        </div>
                                    </div>

                                    <div className="slide-bg-container slide-5-bg-img">
                                        <div className="bg-img-overlay-5-ani-1">
                                            <img src={bgOverLay} alt="bg-image-overlay"></img>
                                        </div>
                                    </div>
                                    

                                    <div className='roadsign1container'>
                                        <img src={roughRoadSign} alt="Rough Road Sign" className="roadsign1"></img>
                                    </div>

                                    <div className='roadsign2container'>
                                        <div className='roadsign2subcontainer'>
                                            <img src={proteinuriaRoadSign} alt="Proteinuria Limit Sign" className='roadsign2'></img>
                                        </div>
                                    </div>

                                    <div className="footnote fn1">
                                        <div className="fn1-subcontainer">
                                            <p>SLE=systemic lupus erythematosus.<br />
                                                <strong>1.</strong> Moroni G, Calatroni M, Ponticelli C. <i>Front Nephrol.</i> 2022;2:984613.</p>
                                        </div>
                                    </div>

                                    <div className="footnote fn2">
                                        <div className="fn2-subcontainer">
                                            <p>ESRD=end-stage renal disease; LN=lupus nephritis; SLE=systemic lupus erythematosus. <br />
                                                <strong>1.</strong> Medina-Rosas J, Fung WA, Su J, Touma Z. <i>Semin Arthritis Rheum.</i> 2018;47(4):557-564. <strong>2.</strong> Hahn BH, McMahon M, Wilkinson A, et al. <i>Arthritis Care Res (Hoboken).</i> 2012;64(6):797-808. <strong>3.</strong> Hanly JG, O’Keeffe AG, Su L, et al. <i>Rheumatology (Oxford).</i> 2016;55(2):252-262. <strong>4.</strong> Fanouriakis A, Kostopoulou M, Andersen J, et al. <i>Ann Rheum Dis.</i> 2024;83:15-29. <strong>5.</strong> Somers EC, Marder W, Cagnoli P, et al. <i>Arthritis Rheumatol.</i> 2014;66(2):369-378.</p>
                                        </div>
                                    </div>

                                    <div className="footnote fn3">
                                        <div className="fn3-subcontainer">
                                            <p>LN=lupus nephritis; SLE=systemic lupus erythematosus.<br />
                                            <strong>1.</strong> Fanouriakis A, Kostopoulou M, Anderson J, et al. <i>Ann Rheum Dis.</i> 2020;79:713-723.</p>
                                        </div>
                                    </div>

                                    <div className="footnote fn4">
                                        <div className="fn4-subcontainer">
                                            <p><sup>a</sup>Patients who had nephritis symptoms for ≥6 months vs &lt;6 months at time of biopsy. Median follow-up time was 6.1 years.<sup>4</sup><br />EULAR=European Alliance of Associations for Rheumatology; LN=lupus nephritis; SLE=systemic lupus erythematosus.<br /> <strong>1.</strong> Medina-Rosas J, Fung WA, Su J, Touma Z. <i>Semin Arthritis Rheum.</i> 2018;47(4):557-564.  <strong>2.</strong> De Rosa M, Sánchez Rocha A, De Rosa G, Dubinsky D, Almaani SJ, Rovin BH. <i>Kidney Int Rep.</i> 2020;5(7):1066-1068.  <strong>3.</strong> Fanouriakis A, Kostopoulou M, Andersen J, et al. <i>Ann Rheum Dis.</i> 2024;83:15-29.<strong> 4.</strong> Faurschou M, Kostopoulou M, Andersen J, et al. <i className="nowrap">J Rheumatol.</i> 2006;33(8):1563-1569.</p>
                                        </div>
                                    </div>
                                    {/*
                                  <div className="desktopMenu">
                                        <nav className="footer-nav d-flex flex-row align-items-center justify-content-center mb-3">
                                            <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/privacy-statement" target="_blank">Privacy Policy</a>
                                            <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/terms-of-use" target="_blank">Terms of Use</a>
                                            <a className="excludeme footer-nav-item cmp-revoke-consent" href="#">Cookie Preferences</a>
                                        </nav>

                                    </div>t */}

                                    <section className="panel slide1" id="slide1">

                                        <div className="slide1inner d-flex align-items-center">
                                            <div className="main-copy-container slide1animation slideonealign">
                                                <h1>Recognize the warning <br />signs of lupus nephritis</h1>
                                                <div className="sub-copy-container slide1animation mt-4">
                                                    <img src={alert_icon} alt="Alert icon" className='alert-icon'></img>
                                                    <h2 className="slide1h2">Are you identifying all the signs that indicate a rough road may be ahead for your patients?</h2>
                                                </div>
                                                <div className="openCta slide1animation">
                                                    <p>Lupus nephritis (LN) is one of the most severe organ manifestations of SLE<sup>1</sup></p>
                                                    <img src={scrollimage} alt="Scroll icon" className='scroll-icon anchor' data-name="Morbidity_scroll_arrow_button" href="panel1" style={{ cursor: "pointer" }}></img>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                    <section className="panel slide2" id="slide2" data-pin="true">
                                        <div className="sub-animation">
                                            <div className="slide2inner d-flex align-items-center">
                                                <div className="main-copy-container slide2animation">
                                                    <h1 className="uncap s2a" style={{ maxWidth: "720px" }}>Leaving LN unchecked puts your patients at risk for severe outcomes<sup>1</sup></h1>
                                                    <p className="paragraphText s2b pt-3">Up to 60% of patients with SLE will develop LN, with potential complications and consequences that can include ESRD and premature mortality.<sup>2</sup> On top of the morbidity associated with SLE, patients with LN had an extra 3x risk of death.<sup>3</sup></p>
                                                    
                                                    <p className="paragraphText s2b pt-3 pt-xl-4">Guidelines recommend vigilant monitoring for LN and recognize that delaying the diagnosis has profound prognostic repercussions.<sup>4</sup></p>
                                                    <p className="paragraphTextYellow s2a pt-3">Heed the warning signs to help reduce your<br />patients’ risk for severe complications of LN.</p>
                                                    <a className="paragraphTextRedLink1 anchor s2c pt-3" href="panel4" data-name="Morbidity_sign_up_button" >Sign up to learn more <img src={rightarrowred} alt="Sign Up Icon" className='sign-up-icon'></img></a>
                                                    <div className="threepoint4"><img src={threepointfour} alt="" className='threefourImg'></img></div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="panel slide3" id="slide3" data-pin="true">
                                        <div className="sub-animation">
                                            <div className="slide3inner d-flex align-items-center">
                                                <div className="main-copy-container slide3animation slideonealign">
                                                    <h1 className="s3a">RECOGNIZE THE WARNING SIGNS OF LUPUS NEPHRITIS</h1>
                                                    <div className="sub-copy-container slide3animation s3b mt-4">
                                                        <img src={alert_icon} alt="Alert icon" className='alert-icon'></img>
                                                        <h2 className="slide3text">Do you know the proteinuria levels of your patients with SLE?</h2>
                                                    </div>
                                                    <div className="openCta slide3animation s3c">
                                                        <p>Screening for proteinuria can tell you when it’s time for a kidney biopsy to diagnose LN<sup>1</sup></p>
                                                        <img src={scrollimage} alt="Scroll icon" className='scroll-icon anchor' data-name="Protenuria_scroll_arrow_button" href="panel3" style={{ cursor: "pointer" }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="panel slide4" id="slide4" data-pin="true">
                                        <div className="sub-animation">
                                            <div className="slide4inner d-flex align-items-center">
                                                <div className="main-copy-container slide4animation">
                                                    <h1 className="uncap s4a">Ignoring proteinuria leaves your patients at risk of poor renal outcomes<sup>1</sup></h1>
                                                    <p className="paragraphText s4b pt-3">Most patients with proteinuria &gt;0.5 g/day already showed significant kidney
                                                        damage upon biopsy.<sup>2</sup></p>
                                                    <p className="paragraphText s4b pt-3">EULAR 2023 guidelines recommend assessing SLE disease activity at every clinic visit and vigilant monitoring for new organ involvement, especially LN.<sup>3</sup></p>
                                                    <p className="paragraphTextYellow s4a pt-3 pt-xl-4">Heed the warning signs to help reduce your patients’ risk for severe complications of LN.</p>
                                                    <a className="paragraphTextRedLink1 s4c pt-3 anchor" href="panel4" data-name="Protenuria_sign_up_button">Sign up to learn more <img src={rightarrowred} alt="Sign Up Icon"  className='sign-up-icon'></img></a>
                                                </div>
                                                <div className="nineximagecontainer"><img src={nineximage} alt="" className='nineximage'></img></div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="panel slide5" id="slide5">
                                        <div className="slide5inner d-flex align-items-center flex-column justify-content-center">
                                            <div className="main-copy-container  mw-80">
                                                <h1 className="s5a" style={{ maxWidth: "100vw" }}>Register to keep learning more about Lupus Nephritis</h1>
                                            </div>
                                            <div className="regForm topOffset">
                                                <div className='form-wrapper s5b'>
                                                    <SignUpForm />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mobilecomponent">

                    <a className="anchor" id="morbidity-and-mortality"></a>
                    <section className="bg-one d-flex flex-column pb-5" id="mobile1">
                        <div className="container">
                            <h1 className="ovrh1 upperCase py-3 pt-5">Recognize the warning signs of lupus nephritis</h1>
                            <div className="row py-1">
                                <div className="col-12 d-flex flex-row align-items-start">
                                    <img src={alert_icon} alt="Alert icon" className='alert-icon mx-0 my-2'></img>
                                    <h2 className="ovrh2">Are you identifying all the signs that indicate a rough road may be ahead for your patients?</h2></div></div>
                            <div className="bottomthird  py-3">
                                <div className="mobile-cta d-flex flex-row">
                                    <p className="mobile-cta-text">Lupus nephritis (LN) is one of the most severe organ manifestations of SLE<sup>1</sup></p>
                                    <a href="#morbidity-and-mortality-2" onClick={mobileGAEvents} data-name="Morbidity_scroll_arrow_button"> <img src={scrollimagemobile} alt="Scroll icon" className='scroll-icon-mobile'></img></a>
                                </div>
                                <p className="mobile-footnote pb-3">SLE=systemic lupus erythematosus.<br />
                                    1. Moroni G, Calatroni M, Ponticelli C. <i>Front Nephrol</i>. 2022;2:984613.</p>
                            </div>
                        </div>
                    </section>

                    <a className="anchor" id="morbidity-and-mortality-2"></a>
                    <section className="d-flex flex-column  pb-4" id="mobile2">
                        <div className="dividercontainer">
                            <img src={morbidityMortalityDivider} alt="Morbidity and Mortality"></img>
                        </div>
                        <div className="container">
                            <h1 className="ovrh1 py-3 pt-3">Leaving LN unchecked puts your patients at risk for severe outcomes<sup>1</sup></h1>
                            <p className="paragraphText py-3">Up to 60% of patients with SLE will develop LN, with potential complications and consequences that can include ESRD and premature mortality.<sup>2</sup> On top of the morbidity associated with SLE, patients with LN had an extra 3x risk of death.<sup>3</sup></p>
                           
                            <p className="paragraphText py-3">Guidelines recommend vigilant monitoring for LN and recognize that delaying the diagnosis has profound prognostic repercussions.<sup>4</sup></p>
                            <div className="threepoint4mobile d-flex flex-row-reverse"><img src={threepointfourmobile} alt="" className='threefourImgmobile'></img></div>
                            <p className="paragraphTextYellow py-3">Heed the warning signs to help reduce your patients’ risk for severe complications of LN.</p>
                            <a className="paragraphTextRedLink1 py-3" href="#sign-up" onClick={mobileGAEvents} data-name="Morbidity_sign_up_button">Sign up to learn more <img src={rightarrowred} alt="Sign Up Icon" className='sign-up-icon'></img></a>

                            <p className="mobile-footnote pt-4">ESRD=end-stage renal disease; LN=lupus nephritis; SLE=systemic lupus erythematosus. <br />
                            <strong>1.</strong> Medina-Rosas J, Fung WA, Su J, Touma Z. <i>Semin Arthritis Rheum.</i> 2018;47(4):557-564. <strong>2.</strong> Hahn BH, McMahon M, Wilkinson A, et al. <i>Arthritis Care Res (Hoboken).</i> 2012;64(6):797-808. <strong>3.</strong> Hanly JG, O’Keeffe AG, Su L, et al. <i>Rheumatology (Oxford).</i> 2016;55(2):252-262. <strong>4.</strong> Fanouriakis A, Kostopoulou M, Andersen J, et al. <i>Ann Rheum Dis.</i> 2024;83:15-29. <strong>5.</strong> Somers EC, Marder W, Cagnoli P, et al. <i>Arthritis Rheumatol.</i> 2014;66(2):369-378.</p>
                        </div>
                    </section>
                    <a className="anchor" id="proteinuria-limits"></a>
                    <section className="bg-two d-flex flex-column" id="mobile3">
                        <div className="dividercontainer">
                            <img src={proteinuriadivider} alt="Proteinuria Divider"></img>
                        </div>
                        <div className="container">
                            <h1 className="ovrh1 upperCase  py-3 pt-3">Recognize the warning <br/>signs of lupus nephritis</h1>
                            <div className="row  py-3">
                                <div className="col-12 d-flex flex-row align-items-start">
                                    <img src={alert_icon} alt="Alert icon" className='alert-icon mx-0 my-2'></img>
                                    <h2 className="ovrh2">Do you know the proteinuria levels of your patients with SLE?</h2></div></div>
                            <div className="bottomthird py-3">
                                <div className="mobile-cta d-flex flex-row">
                                    <p className="mobile-cta-text">Screening for proteinuria can tell you when it’s time for a kidney biopsy to diagnose LN<sup>1</sup></p>
                                    <a href="#proteinuria-limits-2" onClick={mobileGAEvents} data-name="Protenuria_scroll_arrow_button">  <img src={scrollimagemobile} alt="Scroll icon" className='scroll-icon-mobile'></img></a>
                                </div>
                                <p className="mobile-footnote mb-4">LN=lupus nephritis; SLE=systemic lupus erythematosus.<br />
                                <strong>1.</strong> Fanouriakis A, Kostopoulou M, Anderson J, et al. <i>Ann Rheum Dis.</i> 2020;79:713-723.</p>
                                 
                            </div>
                        </div>
                    </section>

                    <a className="anchor" id="proteinuria-limits-2"></a>
                    <section className="d-flex flex-column pb-4" id="mobile4">
                        <div className="dividercontainer">
                            <img src={proteinuriadivider} alt="Proteinuria Divider"></img>
                        </div>
                        <div className="container">
                            <h1 className="ovrh1 py-3 pt-3">Ignoring proteinuria leaves your patients at risk of poor renal outcomes<sup>1</sup></h1>
                            <p className="paragraphText py-3 pt-4">Most patients with proteinuria &gt;0.5 g/day already showed significant kidney damage upon biopsy.<sup>2</sup></p><p className="paragraphText py-3">
                            EULAR 2023 guidelines recommend assessing SLE disease activity at every clinic visit and vigilant monitoring for new organ involvement, especially LN.<sup>3</sup></p>
                            <div className="nineximagecontainermobile d-flex flex-row-reverse pt-3"><img src={nineximagemobile} alt="" className='nineximagemobile'></img></div>
                            <p className="paragraphTextYellow py-3">Heed the warning signs to help reduce your patients’ risk for severe complications of LN.</p>
                            <a className="paragraphTextRedLink1  py-3" href="#sign-up" onClick={mobileGAEvents} data-name="Protenuria_sign_up_button">Sign up to learn more <img src={rightarrowred} alt="Sign Up Icon" className='sign-up-icon'></img></a>

                            <p className="mobile-footnote pt-4"><sup>a</sup>Patients who had nephritis symptoms for ≥6 months vs &lt;6 months at time of biopsy. Median follow-up time was 6.1 years.<sup>4</sup><br />EULAR=European Alliance of Associations for Rheumatology; LN=lupus nephritis; SLE=systemic lupus erythematosus.<br /> <strong>1.</strong> Medina-Rosas J, Fung WA, Su J, Touma Z. <i>Semin Arthritis Rheum.</i> 2018;47(4):557-564.  <strong>2.</strong> De Rosa M, Sánchez Rocha A, De Rosa G, Dubinsky D, Almaani SJ, Rovin BH. <i>Kidney Int Rep.</i> 2020;5(7):1066-1068.  <strong>3.</strong> Fanouriakis A, Kostopoulou M, Andersen J, et al. <i>Ann Rheum Dis.</i> 2024;83:15-29.<strong> 4.</strong> Faurschou M, Kostopoulou M, Andersen J, et al. <i>J Rheumatol.</i> 2006;33(8):1563-1569.</p>
                        </div>
                    </section>
                    <a className="anchor" id="sign-up"></a>
                    <section className="bg-three d-flex flex-column" id="mobile6">
                        <div className="dividercontainer2">
                            <img src={signupdivider} alt="Sign Up Divider"></img>
                        </div>
                        <div className="container">
                            <h1 className="ovrh1 upperCase py-3 pt-3">Register to keep learning more about LUPUS NEPHRITIS</h1>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10" id="RegisterForm">
                                    <SignUpFormMobile />
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="footer">
                        <div className="row justify-content-center">
                            <div className="col-11 col-xl-8 text-center mt-3">
                                <p className="mb-0 text-center">The information on this website is provided by Aurinia for education purposes only and is intended for US health care professionals only. It should not be used for diagnosis or treating a health problem or disease.</p>
                            </div>
                        </div>
                       <div className="row justify-content-center">
                            <div className="col-12 col-lg-6 text-center my-2">
                                <a href="https://www.auriniapharma.com/" target="_blank"><img src={AuriniaLogomobile} alt="Aurinia Logo" className='footer-logo mb-4 mb-lg-3'></img></a>
                               {/* <nav className="footer-nav d-flex flex-row align-items-center justify-content-center mb-3">
                                    <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/privacy-statement" target="_blank">Privacy Policy</a>
                                    <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/terms-of-use" target="_blank">Terms of Use</a>
                                    <ShowCookieComponent />
                                </nav>*/}
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-10 col-lg-6">
                                <p className="pb-0 mb-1 text-center">Trademarks and logos are the property of Aurinia Pharmaceuticals Inc.<br />
                                    ©2024 Aurinia Pharma U.S., Inc. <br />All rights reserved. (09/24) US-NA-2400204</p>
                            </div>
                        </div>

                    </footer>
                </div>

            </div>
        </main>
    );
}
